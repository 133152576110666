<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>AI电竞大赛</el-breadcrumb-item>
      <el-breadcrumb-item>{{ !id ? '新增' : '编辑'}}机构</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <div class="page-title">{{ !id ? '新增' : '编辑'}}机构</div>
      <el-form label-width="140px" :model="form" :rules="formRulers" ref="formRef" class="form1">
        <div class="title-1">1、{{ !id ? '选择' : ''}}机构<div class="tips" v-if="!id">请选择机构，如未查询到数据，请先前往“B端数据管理”-“学校/机构管理”新增。</div></div>
        <el-form-item label="机构地区:" prop="schoolArea">
          <span v-if="id">
            {{ form.province }}/{{ form.city }}/{{ form.area }}
          </span>
          <el-cascader v-else placeholder="请选择" v-model="schoolArea" ref="schoolAreaRef" :options="areasData"
            style="width: 400px"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'areaId',
              children: 'areas',
            }"
            @change="provincesHandleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="机构:" prop="schoolId">
          <span v-if="id">
            {{ schoolInfo.schoolName }}
          </span>
          <el-select v-else style="width: 400px" placeholder="请选择" v-model="form.schoolId"
            @change="schoolSelectChange" filterable clearable>
            <el-option v-for="item in provincesSchoolList" :key="item.id" :label="item.schoolName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-divider></el-divider> -->
        <div class="title-1">2、设置基础信息</div>
        <el-form-item label="LOGO:" prop="logo" style="width: 100%;">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :headers="headers"
            :data="{
              filePath: 'web/AICompetition/image'
            }"
            accept="image/jpeg,image/gif,image/png,image/jpg"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="机构名称:" prop="name">
          <el-input style="width: 400px;" v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="详细地址:" prop="address">
          <el-input style="width: 400px;" v-model="form.address" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="机构类型:" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio label="area">地区</el-radio>
            <el-radio label="country">集团</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电竞馆:" prop="stadium">
          <el-radio-group v-model="form.stadium">
            <el-radio :label="1">开通</el-radio>
            <el-radio :label="0">不开通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="负责人:" prop="liablePerson">
          <el-input style="width: 400px;" v-model="form.liablePerson" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="机构联系方式:" prop="liablePhone">
          <el-input style="width: 400px;" v-model="form.liablePhone" placeholder="请输入"></el-input>
          <div class="tips tips1" v-if="id"><i class="el-icon-info"></i>修改机构联系方式，不会同步开通拼词之星教师端账号。</div>
        </el-form-item>
        <el-form-item label="扩展人:" prop="expander">
          <el-input style="width: 400px;" v-model="form.expander" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="note">
          <el-input style="width: 400px;" v-model="form.note" :rows="4" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div class="title-1">3、设置分成比例</div>
      <el-form label-width="100px" ref="formRef2">
        <div class="form2" v-for="(item, i) in form2" :key="i">
          <el-button v-if="form2.length > 1" class="remove" @click="removeFn(i)" type="danger" icon="el-icon-minus" size="mini" circle></el-button>
          <el-form-item label="缴费人数:" prop="lowNum" class="form-item-1">
            <el-input style="width: 150px;" v-model="item.lowNum" placeholder="请输入"></el-input>
            ～
            <el-input style="width: 150px;" v-model="item.highNum" placeholder="请输入"></el-input> 人
          </el-form-item>
          <el-form-item label="官方收入比例:" prop="officialRatio">
            <el-input style="width: 120px;" v-model="item.officialRatio" placeholder="请输入"></el-input> %
          </el-form-item>
          <el-form-item label="机构收入比例:" prop="mechanismRatio">
            <el-input style="width: 120px;" v-model="item.mechanismRatio" placeholder="请输入"></el-input> %
          </el-form-item>
          <el-form-item label="奖金比例:" prop="bonusRatio">
            <el-input style="width: 120px;" v-model="item.bonusRatio" placeholder="请输入"></el-input> %
          </el-form-item>
          <el-form-item label="成本比例:" prop="costRatio">
            <el-input style="width: 120px;" v-model="item.costRatio" placeholder="请输入"></el-input> %
          </el-form-item>
          <el-form-item label="备注:" prop="note" class="form-item-2">
            <el-input style="width: 80%;" v-model="item.note" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center; margin-top: 20px;">
          <el-button type="primary" plain size="small" icon="el-icon-plus" style="width: 120px;" @click="addFn">新增</el-button>
        </div>
      </el-form>
      <div style="margin-top: 80px;display: flex; justify-content: center;">
        <el-button type="primary" plain style="width: 140px" @click="goBack">返 回</el-button>
        <el-button type="primary" style="width: 140px" @click="saveFn">保 存</el-button>
      </div>
      <el-divider v-if="id && list.length"></el-divider>
      <div class="infos" v-if="id && list.length">
        <div class="info-title">授权信息：</div>
        <div class="item" v-for="(item, i) in list" :key="i">
          <template v-if="item.accredit">
            <div class="flex">
              <div class="label">【授权】起止时间：</div>
              <div>{{ item.accreditBeginTime }} ~ {{ item.accreditEndTime }}</div>
            </div>
          </template>
          <div class="flex" v-else>
            <div class="label">【取消授权】</div>
          </div>
          <div class="flex">
            <div class="label">备注：</div>
            <div class="value v1">{{ item.note }}</div>
          </div>
          <div class="flex">
            <div class="label">操作时间：</div>
            <div class="value">{{ item.operationTime }}</div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getAllAreaList, getSchoolListWithCity } from '@/http/api'
export default {
  data () {
    return {
      areasData: [],
      schoolArea: [],
      provincesSchoolList: [],
      form: {
        name: '',
        schoolId: '',
        provinceCode: '',
        province: '',
        cityCode: '',
        city: '',
        areaCode: '',
        area: '',
        address: '',
        type: 'area',
        note: '',
        logo: '',
        expander: '',
        liablePerson: '', // 负责人
        liablePhone: '', // 机构联系方式
        stadium: 1
      },
      form2: [{
        officialRatio: '',
        lowNum: '',
        highNum: '',
        mechanismRatio: '',
        bonusRatio: '',
        costRatio: '',
        note: ''
      }],
      schoolInfo: {},
      formRulers: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }],
        logo: [{ required: true, message: '请选择', trigger: 'change' }],
        liablePerson: [{ required: true, message: '请输入', trigger: 'blur' }],
        liablePhone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      imageUrl: '',
      uploadUrl: '',
      headers: {
        // Terminal: 'WEB',
        Authorization: localStorage.getItem('Authorization')
      },
      id: '',
      list: []
    }
  },
  created () {
    this.id = this.$route.query.id
    this.uploadUrl = this.$env.baseIP + 'upload/uploadFileAliOss'
    if (this.id) {
      this.getInfo()
    } else {
      this.getAllAreaList()
    }
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    addFn () {
      this.form2.push({
        officialRatio: '',
        lowNum: '',
        highNum: '',
        mechanismRatio: '',
        bonusRatio: '',
        costRatio: '',
        note: ''
      })
    },
    removeFn (i) {
      this.form2.splice(i, 1)
    },
    getInfo () {
      this.$http.get('/esportsMatchMechanism/info', { params: { id: this.id } }).then(res => {
        if (res.code === 200) {
          this.form = {
            name: res.data.name,
            schoolId: res.data.schoolId,
            provinceCode: res.data.provinceCode,
            province: res.data.province,
            cityCode: res.data.cityCode,
            city: res.data.city,
            areaCode: res.data.areaCode,
            area: res.data.area,
            address: res.data.address,
            type: res.data.type,
            note: res.data.note,
            logo: res.data.logo,
            expander: res.data.expander,
            liablePerson: res.data.liablePerson, // 负责人
            liablePhone: res.data.liablePhone,
            stadium: res.data.stadium
          }
          this.schoolInfo = res.data.school
          // this.$set(this, 'schoolArea', [this.form.provinceCode, this.form.cityCode, this.form.areaCode])
          // this.getSchoolListData(this.schoolArea)
          console.log(this.schoolArea)
          this.imageUrl = res.data.logo
          this.list = res.data.esportsMatchMechanismAcreditLogs || []
          if (res.data.dividends) {
            this.form2 = res.data.dividends
          }
        }
      })
    },
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.form.name = ''
      this.form.schoolId = ''
      this.form.provinceCode = value[0]
      this.form.cityCode = value[1]
      this.form.areaCode = value[2]
      const areaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = areaName.split('/')
      this.form.province = areaList[0]
      this.form.city = areaList[1]
      this.form.area = areaList[2]
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    schoolSelectChange (schoolId) {
      // console.log(this.provincesSchoolList, schoolId)
      this.form.schoolId = schoolId
      this.form.name = this.provincesSchoolList.find(item => item.id === schoolId).schoolName
    },
    goBack () {
      this.$router.back()
    },
    saveFn () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        const len = this.form2.filter(item => item.officialRatio === '' || item.lowNum === '' || item.highNum === '' || item.mechanismRatio === '' || item.bonusRatio === '' || item.costRatio === '').length
        if (len) {
          return this.$message.warning('请设置分成比例')
        }
        this.form.dividends = this.form2
        const url = this.id ? '/esportsMatchMechanism/edit' : '/esportsMatchMechanism/create'
        if (this.id) {
          this.form.id = this.id
        }
        this.$http.post(url, this.form).then(res => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.goBack()
          }
        })
      })
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      console.log(res)
      if (res.code === 200) {
        this.form.logo = res.data
      }
    },
    beforeAvatarUpload (file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    }
  }
}
</script>
<style lang="less" scoped>
.tips{
  font-size: 12px;
  color: #8c939d;
  text-align: center;
  margin-left: 10px;
  display: inline-block;
}
.tips1{
  text-align: left;
}
.page-title{
  font-size: 16px;
  color: #000;
  margin-bottom: 40px;
  text-align: center;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .form1{
  width: 1120px;
  margin: 0 auto;
}
::v-deep .form1 .el-form-item{
  width: 540px;
  margin: 0 auto 22px;
  display: inline-block;
  vertical-align: top;
}
::v-deep .form2 {
  width: 1120px;
  margin: 0 auto 20px;
  padding: 20px;
  border: 1px dashed #409EFF;
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
  .remove{
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
::v-deep .form2 .el-form-item{
  width: 25%;
  display: inline-block;
}
::v-deep .form2 .form-item-1.el-form-item{
  width: 500px;
  display: block;
}
::v-deep .form2 .form-item-2.el-form-item{
  width: 1120px;
  display: block;
}
.infos{
  width: 1120px;
  font-size: 12px;
  color: #666;
  line-height: 20px;
  margin: 40px auto;
}
.flex{
  display: flex;
}
.item{
  margin-bottom: 10px;
  border-bottom: 1px dashed #999;
  padding-bottom: 10px;
  &:last-child{
    border: none;
  }
}
.info-title{
  font-weight: bold;
  margin-bottom: 10px;
}
.v1{
  white-space: pre-line;
}
.title-1{
  width: 1120px;
  margin: 40px auto;
  font-size: 16px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 4px;
}
</style>
